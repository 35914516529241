import React from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import Layout from '../components/layout'
import SEO from '../components/seo'
import img1 from '../images/biografi/1.jpg'
import img2 from '../images/biografi/2.jpg'
import img3 from '../images/biografi/3.jpg'
import img4 from '../images/biografi/4.jpg'
import img5 from '../images/biografi/5.jpg'
import img6 from '../images/biografi/6.jpg'
import img7 from '../images/biografi/7.jpg'
import img8 from '../images/biografi/8.jpg'
import img9 from '../images/biografi/9.jpg'
import img10 from '../images/biografi/10.jpg'
import img11 from '../images/biografi/11.jpg'
import img12 from '../images/biografi/12.jpg'
import img13 from '../images/biografi/13.jpg'
import img14 from '../images/biografi/14.jpg'
import img15 from '../images/biografi/15.jpg'
import img16 from '../images/biografi/16.jpg'
import img17 from '../images/biografi/17.jpg'
import img18 from '../images/biografi/18.jpg'
import img19 from '../images/biografi/19.jpg'
import img20 from '../images/biografi/20.jpg'
import img21 from '../images/biografi/21.jpg'
import img22 from '../images/biografi/22.jpg'
import img23 from '../images/biografi/23.jpg'
import img24 from '../images/biografi/24.jpg'
import img25 from '../images/biografi/25.jpg'
import img26 from '../images/biografi/26.jpg'
import img27 from '../images/biografi/27.jpg'
import img28 from '../images/biografi/28.jpg'
import img29 from '../images/biografi/29.jpg'
import img30 from '../images/biografi/30.jpg'
import img31 from '../images/biografi/31.jpg'
import img32 from '../images/biografi/32.jpg'
import img33 from '../images/biografi/33.jpg'
import img34 from '../images/biografi/34.jpg'
import img35 from '../images/biografi/35.jpg'
import img36 from '../images/biografi/36.jpg'
import img37 from '../images/biografi/37.jpg'
import img38 from '../images/biografi/38.jpg'
import img39 from '../images/biografi/39.jpg'
import img40 from '../images/biografi/40.jpg'
import img41 from '../images/biografi/41.jpg'
import img42 from '../images/biografi/42.jpg'
import img43 from '../images/biografi/43.jpg'
import img44 from '../images/biografi/44.jpg'
import img45 from '../images/biografi/45.jpg'

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `biografi`,
          `nyström`,
          `karl-åke`,
          `tidaholm`,
          `laila`,
          `van gogh`,
          `indisk elefant`,
        ]}
        title="Biografi"
      />
      <section className="bg-gray-200 p-2">
        <article className="container mx-auto prose pt-12">
          <p>
            Detta är en digital kopia av Karl-Åke Nyströms självbiografi "Van
            Gogh och en indisk elefant". Genom att klicka på rubriker i
            innehållsförteckningen tas du till respektive kapitel.
          </p>

          <div className="bg-white shadow mb-8 p-4 rounded">
            <h1>VAN GOGH OCH EN INDISK ELEFANT</h1>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img1}
                alt=""
              />
            </Zoom>
            <div>
              Copyright Karl-Åke Nyström 1985
              <br />
              Repro AB June litografiska
              <br />
              Jönköping
              <br />
              <br />
              Offsettryckning
              <br />
              Labyrintens litografiska tryckeri
              <br />
              Tidaholm
              <br />
              <br />
              Bokbinderi Falköpings bokbinderi
              <br />
              Falköping
              <br />
              <br />
              Digitalisering Alexander Nyström 2014
              <br />
            </div>
            <h2>Innehållsförteckning</h2>
            <a href="#inledande">
              <p className="bold text-sm italic">En inledande bild</p>
            </a>
            <a href="#hosmormorochmorfar">
              <p className="bold text-sm italic">Hos mormor och morfar</p>
            </a>
            <a href="#motemedmannen">
              <p className="bold text-sm italic">
                Möte med mannen som älskade livet
              </p>
            </a>
            <a href="#julklapp">
              <p className="bold text-sm italic">En julklapp för hela livet</p>
            </a>
            <a href="#indiskelefant">
              <p className="bold text-sm italic">
                Hur en indisk elefant förändrar livet
              </p>
            </a>
            <a href="#konstochlara">
              <p className="bold text-sm italic">
                Konst och lära ger gunst och ära
              </p>
            </a>
            <a href="#tidaholm">
              <p className="bold text-sm italic">Det blev Tidaholm</p>
            </a>
            <a href="#irrgangar">
              <p className="bold text-sm italic">I Labyrintens irrgångar</p>
            </a>
            <a href="#folkhogskolanslabyrinter">
              <p className="bold text-sm italic">I folkhögskolans labyrinter</p>
            </a>
            <a href="#konstnarsforening">
              <p className="bold text-sm italic">
                Skaraborgs konstnärsförening
              </p>
            </a>
            <a href="#svara">
              <p className="bold text-sm italic">Svåra år</p>
            </a>
            <a href="#kulturenslabyrinter">
              <p className="bold text-sm italic">Kulturens labyrinter</p>
            </a>
            <a href="#litografiskaverkstaden">
              <p className="bold text-sm italic">Litografiska verkstaden</p>
            </a>
            <a href="#fargpastan">
              <p className="bold text-sm italic">Sätt färg på stan</p>
            </a>
            <a href="#ljuset">
              <p className="bold text-sm italic">Resor till ljuset</p>
            </a>
            <a href="#kreta">
              <p className="bold text-sm italic">Kreta - Knossos</p>
            </a>
            <a href="#silver">
              <p className="bold text-sm italic">Morfars silvertrådar</p>
            </a>
            <a href="#samarbete">
              <p className="bold text-sm italic">Samarbete</p>
            </a>
            <a href="#labyrintenslitografiska">
              <p className="bold text-sm italic">Labyrintens litografiska</p>
            </a>
            <a href="#tavlor">
              <p className="bold text-sm italic">
                10 reproduktioner av mina tavlor
              </p>
            </a>
            <a href="#baksida">
              <p className="bold text-sm italic">Bokens baksida</p>
            </a>
          </div>

          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="inledande">En inledande bild</h2>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img2}
                alt=""
              />
            </Zoom>
            <p>
              Vi lever i dag i datorernas värld, datorer som på sina disketter
              har väldiga mängder av fakta lagrade. När jag tänker mig mitt liv
              lagrat på en sådan diskett, avser jag här att enbart ta fram
              händelser vilka påverkat min strävan och utveckling mot ett
              konstnärskap. Om det spännande i att uppleva och försöka återskapa
              det upplevda, är vad jag vill berätta.
            </p>
            <Zoom>
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img3}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              Söndag hos mormor och morfar. Lavering från 1949.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="hosmormorochmorfar">Hos mormor och morfar</h2>
            <p>
              Mina första stora bildupplevelser fick jag hos min mormor och
              morfar när jag på söndagarna satt i morfars knä och han läste för
              mig ur bibelns gamla testamente - en bok full av krig och äventyr
              som fascinerade mig. Berättelserna var illustrerade av Gustav
              Doré. Hans realistiska återgivning av berättelserna gav mig en
              bild av de gammaltestamentliga människorna och även när morfar
              inte läste för mig hängde jag över bibelns bilder och föreställde
              mig själv som David i hans kamp mot Goliat. Under hela min barndom
              försökte jag alltid tillverka saker. Det var ett behov som aldrig
              blev riktigt tillfredsställt, då de föremål jag gjorde aldrig blev
              sådana jag tänkt mig. Möjligheterna att "göra" saker var annars
              stora för mig. Min morbror hade en verkstad hemma på gården. Han
              drömde hela sitt liv om att konstruera och uppfinna maskiner
              vilket han också gjorde i mindre omfattning, bl.a. en maskin som
              tillverkade kedjor för säng-bottnar. Hans verkstad var en
              guldgruva för en pojke som längtade efter att göra saker.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="mötemedmannen">Möte med mannen som älskade livet</h2>
            <p>
              Min första och avgörande kontakt med konstnärligt skapande skedde
              av en slump. Mina föräldrar och jag bilade ner till Göteborg på
              tidigt 40-tal. Anledningen har jag glömt, men följderna blev
              livsavgörande för mig. På Göteborgs konstmuseum visades den första
              retrospektiva utställningen i Sverige av Vincent van Goghs måleri
              och av någon underlig anledning vandrade jag Avenyn fram till
              Götaplatsen. Nyfiken fortsatte jag upp för trapporna till
              konstmuseet och steg in - till en för mig tidigare okänd värld.
              Vincent van Goghs expressiva målningar fyllda av lysande färger
              borrade sig in i mitt medvetande och lyfte mig mot ett tillstånd
              jag vill beteckna som ett med Gud - en religiös upplevelse som jag
              sedan dess har letat efter. Denna upplevelse på Göteborgs
              konstmuseum hade till följd att jag började drömma om att bli
              konstnär. Jag lånade böcker, biografier om van Gogh och om
              impressionisterna i Frankrike och i mina tonårsdrömmar ville jag
              uppleva lika starkt som van Gogh och återskapa samma upplevelser
              som han.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="julklapp">En julklapp för hela livet</h2>
            <p>
              Julen efter vårt Göteborgsbesök fick jag oljefärger i julklapp av
              mina föräldrar. Strålande glad och med van Goghs solrosor i minnet
              satte jag upp ett stilleben med röda tulpaner och började så min
              bana som "konstnär". Tyvärr upptäckte jag med stor sorg i hjärtat
              ganska så omgående att någon van Gogh var jag inte och att måla i
              olja var något som skulle ta tid att lära sig. Utan mina
              föräldrars stöd, som jag alltid helhjärtat har fått sedan dess,
              skulle jag aldrig ha kunnat studera och efter studierna börjat
              arbeta i de olika tekniker jag sökt mig fram i.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img4}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              Mina föräldrar. Märta och Oskar Nyström.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="indiskelefant">Hur en indisk elefant förändrar livet</h2>
            <p>
              År 1944 var jag elev i Tidaholms realskola. En dag står magistern
              vid katedern och ser underlig ut. På svarta tavlan finns en bild
              av en soldat, en vanlig bild de åren, det rasade ju ett
              världskrig. Har Karl-Åke gjort bilden, frågar han. Jag förstår
              ingenting. Nähä svarar jag och blir orolig men blir lugnad då en
              flicka talar om att hon gjort teckningen - som tröst för magistern
              vilken är inkallad till militär-tjänst. Vi får nu veta varför jag
              tillfrågats: På en teckningslektion hade klassen fått som uppgift
              att måla något från Indien. Av någon anledning kom jag loss från
              den trötthet som alltid rådde sista timman på dagen (det har ju
              alltid varit så inom skolans värld att man lägger konsttimmarna
              då). Min fantasi flödade både över papper och bänk när jag målade
              en indisk elefant med ryttare. Vår teckningslärare som var mycket
              spontan, närmast "knäpp" i våra ögon, kommer och får se min
              indiska målning. Han blir förtjust och erbjuder mig fem kronor för
              bilden. Han stärker därigenom min övertygelse om att han är galen,
              men hjärteglad säljer jag bilden till honom, det blir ju mycket
              godis för fem kronor vid denna tid. Bilden sänder han till en
              barnteckningsutställning i Stockholm utan att tala om detta för
              mig, men i realskolans kollegium noteras min framgång och härav
              magisterns fråga till mig. Varför berättar jag detta? Jo, en dag
              ringer man frän Vulcans litografiska tryckeri och frågar om skolan
              kan rekommendera någon konstnärligt begåvad elev till en
              utbildning som litograf på tryckeriet. Eftersom rektorn hade hört
              om min indiska elefants äventyr i Stockholm ställdes frågan till
              mig, om jag var intresserad av en anställning på Vulcan.
              Säkerligen ansåg rektorn att det var ett enastående tillfälle för
              mig, då jag inte på minsta sätt visat någon flit eller intresse av
              att studera. Som jag tidigare berättat hade jag varit i Göteborg
              och sett den stora van Gogh-utställningen. Här fick nu mina
              konstnärsdrömmar näring och jag såg mig själv i en mysig
              ateljémiljö i full färd med att måla varje dag. För mig var det
              ingen tvekan, men mina föräldrar blev inte glada. De hade ingen
              hand med mig, och efter många tårar fick jag sluta skolan. Nu
              skulle jag bli litograf. Jag hade mycket dunkla begrepp om vad det
              innebar, men tryckeriföreståndaren förklarade för mig vad jag
              skulle syssla med. Det gällde att teckna bilder på stenar, främst
              tändsticksetiketter, som sedan skulle förmeras i massupplagor över
              hela världen. Som lärare skulle jag få en kvinna som hette Ester,
              men tyvärr, sade föreståndaren, var hon sjuk för tillfället, så
              Tryckar-Olle skulle visa mig tillrätta. Tryckar-Ollee var en
              rikskändis i tryckarkretsar - en mycket duglig och kunnig tryckare
              som dessutom band in böcker, stoppade upp fåglar och etsade och
              målade tavlor, men han hade periodvis problem med spriten. Tyvärr
              var det så den dagen Olle skulle ta hand om mig, han välkomnade
              mig med ett vänligt leende som lämnade kvar en doft av blåsprit.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img5}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              I ateljén på Vulcans tryckeri 1945.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img6}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              "Övertrycket". Från vänster: Carl Olausson, Ester Andersson, Felix
              Beckman, Evert Stenberg, jag själv och Anders Kleén.
            </p>
            <p>
              Kom nu pojker skall jag visa dig ateljén, sade han. Jag följde
              förläget Olle genom övertryckarnas rum och vågade knappt titta på
              de skinnförklädesförsedda överlryckarna som jag tyckte stirra på
              mig. Så gick vi upp en trappa och där var ateljén - precis som jag
              hade tänkt mig med snedtak och takfönster samt stora fönster på
              två av väggarna. En vägg täcktes av hyllor liknande vanliga
              bokhyllor men här förvarades en svårläst litteratur. Hyllorna var
              fyllda av upprättstående litografiska stenar som alla var försedda
              med nummerserier. I rummet fanns två ritbord speciellt formgivna
              för litografisk teckning. En bänk var Esters. Den andra är din,
              sade Olle. Sen fortsatte han skrattande: men ska du inte ha en
              pris snus? Nä, svarade jag. Då plötsligt började han gråta,
              tårarna rann i hans rödmosiga ansikte och hans lilla svarta
              mustasch hoppade under den stora röda näsan. Ser du inte fåglarna
              som sitter på stenhyllan, ropade han. De vill åt mig. De fåglar
              Olle trodde sig se var sådana som han stoppat upp åt intresserade
              Tidaholmare, och nu ville fåglarna hämnas på honom. Som tur var
              gick anfallet fort över, men jag hade blivit skrämd, men på något
              sätt ger min första dag i tryckeriet en bild av mina år där. Allt
              var främmande, miljön sliten. Doften av ruttnande trä från
              tändstickstillverkningen lurade utanför dörrarna. Tittade jag ut
              genom fönstren gick tändsticksarbetarna där och sköt vagnar
              lastade med stock framför sig. Det var inte underligt att man döpt
              en del av fabriksområdet till Sibirien, för fångläger var vad jag
              tänkte på. Och med vetskapen om hur fattigt tändsticksarbetarna
              haft det genom åren blev miljön utanför än mer skrämmande. Det var
              en ringa tröst att litografernas löner var något bättre än
              fabriksarbetarnas. Nu hade jag tjatat så hemma för att få börja
              som litograf, så någon möjlighet att sluta fanns inte för mig.
              Besviken och missmodig fortsatte jag i fyra år innan jag bröt mig
              ut från tryckeriet. Varje dag under de här åren ansåg jag som
              förspilld. När jag såg mina kamrater som fortsatt studera gjorde
              det ont i mig - deras framtid var ljus, som det står i sången, min
              var mörk. Nu många år senare har jag omvärderat de här åren. Jag
              hade turen att komma till ett tryckeri som hade kvar det gamla
              hantverksmässiga stentrycket. Ledningen hade inte satsat på den
              modernsering som vid den tiden svepte genom tryckerivärlden. I dag
              när de gamla litografiska teknikerna nästan dött ut, är det en
              stor tillgång att ha fått arbeta med dessa gamla yrkesmän, som
              fört en tradition vidare från generation till generation. En
              teknik som det nu gäller att bevara undan glömskan och till gagn
              för konstnärer i deras produktion av originalgrafik.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="konstochlara">"Konst och lära ger gunst och ära".</h2>
            <p>
              Förlorade år, jag var inte ensam om den uppfattningen. Vi blev ett
              gäng pojkar som brukade träffas på kvällarna. En del av oss hade
              varit skolkamrater och nu hade vi den gemensamma övertygelsen att
              vi ville utbilda oss - inte fastna i den situation vi befann oss
              i. Vi hade en brinnande kunskapslängtan. Detta gäng pojkar
              kallades av våra jämnåriga för "Löjliga gänget". För småstadens
              arbetarpojkar var intressen som konst, litteratur, teater, musik
              och även politik tyvärr något sjukt. För oss i gänget betydde
              detta kamratskap ett stöd att orka med vardagen, att odla och
              fördjupa våra intressen samt till att gå vidare till skolor och
              utbildningar - utan dessa kunskapstörstande kamraters hjälp hade
              jag inte orkat gå vidare och söka in på Slöjdföreningens skola i
              Göteborg. I vårt gäng hade från början Jan Steen blivit min allra
              bästa vän. Han var tidigt övertygad om att försöka komma in på
              Slöjdföreningens skola i Göteborg, som Konstindustriskolan hette
              på den tiden. Jan hade gått fyra år på landstingets verkstadskola
              i Tidaholm. Han hade gesällbrev i möbelsnickeri men ville utbilda
              sig till möbel-arkitekt. Under de här åren var vi ständigt
              tillsammans och målade på de flesta lediga stunder, och våra
              drömmar var tveklöst att få syssla med konstnärligt skapande.
              Uppmuntrade av gänget tog vi mod till oss och reste ner till
              Slöjd-föreningens skola.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img7}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              "Löjliga gänget" samlade 1958 på Stora Hotellet i Tidaholm. Från
              vänster: Åkte Persson, Ingemar Larsson, jag själv, Sven-Inge
              Erlandsson, Lars Billengren, Jan Steen och Glsta Engström. På
              bilden saknas Bruno Ekwall, han stod bakom kameran.
            </p>
            <p>
              När vi kom in genom entren såg vi en devis som för oss verkade
              ålderdomlig i sin form men uppmuntrande: KONST OCH LÄRA GER GUNST
              OCH ÄRA. Styrkta av dessa rader stegade vi in till skolans rektor
              Åke Hampus Hult som vänligt tog emot oss. Han tittade på våra
              teckningar som vi hade med och trodde sig kunna utläsa sådana
              kunskaper hos oss att vi nog skulle klara oss in på skolan, men
              han rekommenderade oss att under sommaren teckna flitigt.
              Beträffande våra drömmar om måleri ansåg han att det var bättre
              att satsa på något yrke som gav större ekonomisk trygghet. Mig
              rekommenderade han silversmide då jag nämnde att yrket verkade
              intressant. Jag hade ju sett min morbror arbeta med metaller till
              sina uppfinningar. Samtidigt var det väl lite nostalgi eller
              kanske intresse för traditioner, min morfars bror som jag då och
              då träffade hade varit smed. Glada i hågen åkte vi hem - slutade
              våra jobb och började teckna mer än vi tidigare gjort. När på
              sensommaren arbetsproverna skulle lämnas hade vi inga problem med
              antalet teckningar. Turligt nog kände jag stadens enda guldsmed
              Axel Svedmar. Han var vänlig mot mig och lät mig praktisera en
              månad i sin verkstad. Så till mina arbetsprover kunde jag bifoga
              några ringar och en armring i silver. Det blev en av de
              lyckligaste dagarna i mitt liv när min mor ringde ner till skolan
              om besked och då det visade sig att rektor Hampus Hults förmodan
              varit riktig - Jan och jag hade klarat konkurrensen.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img8}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              Jan och jag en av de första dagarna i Göteborg.
            </p>
            <p>
              Vi var antagna till den första arskursen på Högre
              Konsthantverkslinjen. Vi hyrde rum tillsammans på Kungshöjd med
              utsikt över Vallgraven och "Feskekörka", en utsikt som vi flitigt
              målade. Silversmide var den teknik jag pa Ake Hults råd hade
              valt,men jag tillbringade lika mycket tid med kroki,
              figurteckning, figurmodell-ring och mätning. Första året gick vi
              då och då upp bakom Konsthallen till Valands konstskola, som då
              lag där. Vissa kvällar kunde man här fä teckna kroki tillsammans
              med Valandseleverna. Under de här åren fick vi några vänner bland
              lärarna som kom att betyda oerhört mycket för vårt kunnande. Att
              äta lagad mat varje dag blev dyrt och var onödigt, ansåg vi, så på
              vardagarna var menyn bröd, smör och ost. Bengt Lindekrants såg
              detta och erbjöd oss att laga mat i hans lärarrum under takåsarna
              på skolan. Det var det bästa som kunnat hända. Det var inte bara
              det att vi fick lagad mat. Bengt satt ofta med när vi åt och han
              gav oss en stor andlig spis - om bild, om att se, om konst - hans
              kunnande var sa stort. Tidigt hade han blivit informerad om
              Bauhausskolan i Tyskland. Han hade litteratur om Klee och
              Kandinsky - för att nämna några namn - och deras idëer och bilder
              förmedlade han till oss. Här en minnesbild - hur Bengt tog upp
              detta viktiga med vår varseblivning och analys för att utveckla
              bildidëer.Vi sitter och äter runt ett stort ritbord. Bengt tar upp
              en ask tändstickor, tömmer ut tändstickorna på bordet. Ser ni
              pojkar säger han på sin göteborgska dialekt, detta är en slags
              ordning, här finns en rytm skapad av naturlagar. Men titta nu om
              jag gör så här, och så flyttar han några tändsticker, nu har jag
              förstärkt den här rörelsen. En annan gång stod en stor bred
              teakplanka lutad mot väggen. Den var säkert minst 35 cm bred och
              nästan tre meter lång och den var full av märken efter sågningen.
              Bengt säger, ser ni plankan pojkar vad spännande och vacker den
              är. Vi satt där som frågetecken, det var ju bara en vanlig planka.
              Bengt ler lite, så skrattar han, titta noga säger han och vi
              tittar, ser ni inte - jo då börjar vi upptäcka, en helt ny värld
              växer fram. Sågningarna har skapat dramatiska spännande händelser
              som vi aldrig tidigare iakttagit. Nu många år efteråt vet jag att
              i Bengts rum fick jag de viktigaste kunskaperna om konst och
              konstnärligt arbete under mina Göteborgsår. Bengts bror Ivar
              Lindekrants - nu känd skulptör men då inte så omtalad - hade fått
              en beställning på en träskulptur till Sundsvalls kyrka. En dag
              bjöd Bengt med oss till Ivar som var nästan klar med skulpturen.
              När vi kom in i Ivars ateljë låg han på knä med knäna instuckna
              lett par träskor. Han var som en förkrympt dvärg. Det gav en
              dramatisk effekt - omedvetet kanske - för bakom honom såg vi
              skulpturgruppen Gudsmän, som gav ett otroligt starkt religiöst
              budskap i sin grovt tillyxade slutna form och med sina klara
              färger. Den gav mig en lika stark upplevelse som van
              Goghutställningen en gång hade gjort. Artur Johansson, vår lärare
              i modellering var en annan av skolans lärare som på sitt lågmälda
              sätt kom att betyda mycket. Rudolf Flink med sina lektioner i
              figurteckning på lördagarna var en annan god förmedlare av
              kunskap. I silversmide var Thore Eldh lärare, en av landets
              tekniskt mest kunniga silversmeder. Med sitt strama reserverade
              men vänliga sätt var han inspirerande. De tekniska problem och
              svårigheter som uppstod klarade han alltid av och gav oss på så
              sätt fördjupade tekniska kunskaper. Thore uppmuntrade mig alltid.
              När jag kom med en skiss som jag hade funderingar på om jag skulle
              klara av att tillverka, sade han, "du skall alltid göra saker som
              du tror att du inte klarar nu, då kommer du att kunna mycket"! Det
              sista året på skolan blev ett slags stipendieår för mig. Jag
              arbetade då som assistentlärare åt Thore Eldh,samtidigt som jag
              gjorde mitt gesällprov i silversmide. Så kom den sista dagen på
              skolan - det definitiva slutet på en lycklig tid. Underbara saker
              hade hänt mig, bl.a. hade jag fått ställa ut silversmide på
              Nationalmuseum, något som jag aldrig hade kunnat drömma om. På
              avslutningen fick jag ta emot Svenska Slöjd-föreningens
              silvermedalj med inskriptionen "För insigt och flit".
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img9}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              Modellstudie från Skoltiden på 50-talet.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="tidaholm">Det blev Tidaholm</h2>
            <p>
              Vemodet och rädslan för framtiden var stor när vi packade in mina
              saker från skolan i fars bil. Det var bara att ge sig av från de
              lyckliga bekymmerslösa dagarna. Riktigt bekymmerslösa hade de ju
              inte varit . Jag och en barndomskamrat Laila blev kära i varann,
              Laila blev med barn och vi gifte oss, under min studietid. Som tur
              var fick vi stor hjälp av mina föräldrar, men nog var det
              bekymmersamt. Under ett besök hemma i Tidaholm blev jag tillfrågad
              om jag var intresserad att ställa ut silversaker i en ur- och
              guldsmedsaffär. Jag lovade att tänka på saken. När så mina
              bekymmer för min blivande familj trängde på, kom jag att tänka på
              förfrågan om en liten utställning. Det kunde ju alltid ge någon
              inkomst. När jag senare tog kontakt om utställningen fick jag höra
              att affären skulle säljas och att det fanns en stark spekulant,
              men en utställning kunde väl göras innan. Sagt och gjort, när jag
              efter utställningen skall hämta mina saker får jag frågan: vill
              inte du köpa affären. Spekulanten hade hoppat av. Vad skulle jag
              svara. Mina intressen låg absolut inte åt det hållet. Jag
              förklarade detta men jag ombads att tänka på saken. Förvirrad tog
              jag upp förslaget om affärsköpet med mina föräldrar och med Laila.
              Affären vår nedgången så det handlade inte om stora pengar det
              visste vi. Till slut kom vi fram till att så osäker som vår
              framtid var hade vi inget att förlora, Laila kunde stå i affären
              och jag skulle fä en verkstad att arbeta i. Med små anspråk skulle
              det gå att försörja sig.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="irrgangar">I Labyrintens irrgångar</h2>
            <p>
              Isoleringen från mina göteborgskamrater, främst då Jan, som jag
              delat så många år av intressanta arbeten med, kändes total. Något
              måste göras. (Västgöta-bladet fick jag se en annons om en
              teckningskurs. Där måste det finnas folk med samma intressen som
              jag själv. Det var bara att anmäla sig till kursen, som leddes av
              Bengt Berglund. Han var målare från Göteborg, hade gått på Valand
              för Nils Nilsson, men för att kunna försörja sig och sin familj
              arbetade han nu i Tidaholm som teckningslärare. Han kände sig
              också väldigt ensam, långt borta från familjen som han var. Det
              var därför helt naturligt att vi började umgås dagligen. För att
              skapa möjligheter till konstnärlig verksamhet i Tidaholm föreslog
              vi deltagarna i teckningskursen att bilda en förening för
              människor som aktivt ville studera och arbeta med konst. De flesta
              av kursdeltagarna hade arbetat i åratal med bilder och hade känt
              samma svårigheter som Bengt och jag hade gjort. De blev därför
              entusiastiska för förslaget. Då alla genom åren känt på de
              vindlingar och sidospår som konststudier vållar, fann man att det
              symboliska namnet Labyrinten skulle passa bra som namn på gruppen.
              I stadgarna skrevs in att föreningen skulle stödja medlemmarnas
              möjligheter att utveckla sina konstnärliga ambitioner. Viktig var
              också en paragraf som framhöll ansvaret för att debatten om
              kulturlivet i stod hölls levande och aktiverades i vår stad.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img10}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              Bengt Berglund har just tecknat av Hans Augustsson, Labyrintens
              ordförande i många år. Hans, är en av mina bästa vänner och har i
              alla år, genom sitt kunnande, sin idealitet och öppenhet berikat
              min tillvaro.
            </p>
            <p>
              Då Bengt efter några år återvände till Göteborg kom Gösta
              Pettersson till våra målningskurser som lärare. Han hade studerat
              på Valand och vid Konstakademin. Efter honom övertog jag
              lärarskapet. Kunskapstörsten var stor i "Labyrinten". För att
              stilla denna törst startade vi sommarkurser i akvarellmålning,
              perceptionsövningar och färgsystematik. Som kursgård fungerade vår
              "egen" stuga Siggestorp, enligt gamla kartor Tidaholms äldsta hus
              med anor från 1600-talet. Huset var en förläning från Vulcans
              tändsticks-fabrik, vilken var ägare till fastigheten, som när vi
              mottog den var i ett bedrövligt skick. Föreningen lade ner ett
              stort arbete vid upprustningen av huset. Som alltid var det
              ekonomin som var svår att klara av. Genom att göra ett lotteri där
              vinsterna utgjordes av tavlor som labyrintarna skänkt klarades det
              problemet av. Här kan det vara på sin plats att beskriva hur många
              tidaholmare såg på konst vid denna tidpunkt. Det var marknad i
              stan och vi hade en utställning med lotterivinsterna och
              lottförsäljning p a marknadsplatsen. Två äldre damer tittar på
              utställningen. En av damerna tänker köpa en lott men får en knuff
              av sin väninna, de bada damerna går ett stycke varefter vi hör
              följande: dä kan la du inte köpa sånna lotter, tavlera ä ju
              hemmagjorda! Trots sådana kommentarer lyckades vi sälja slut på
              värt lotteri. Idëer, bostad och utrustning fanns, men var skulle
              vi finna en lämplig lärare för kursen? Stadens riksdagsman Birger
              Andersson, mycket konstintresserad, bl. a. ordf. i riksdagens
              konstklubb, hade lösningen åt oss. Ni skall åka till Visingsö,
              sade han, där har Konstfrämjandet en kurs nu, med professor Ernst
              Röttger från Tyskland som lärare. Honom får ni nog inte med er
              till Tidaholm, men han har en duktig kille med sig som arbetar åt
              Konstfrämjandet. Han kanske ställer upp. Rune Svensson hette den
              där killen. Erland Lundqvister, hans blivande fru Ulla och jag
              hyrde en sommarkväll en liten motorbåt i Brandstorp, Tidaholms
              "hamnstad" vid Vättern, och med ägaren vid rodret gungade vi över
              sjön. När vi den kvällen steg i land på Visingsö och vandrade
              vägen upp mot folkhögskolan, kändes det som om vi i sommarkvällen
              hade förflyttats hundra år bakåt i tiden till en försvunnen idyll.
              Emellertid fick vi fatt på Rune och han lovade att komma till oss
              som kursledare. Vad vi inte visste då, det var att den resan
              skulle så småningom förändra både Runes och mitt liv.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="folkhogskolanslabyrinter">I folkhögskolans labyrinter</h2>
            <p>
              Under första året i Tidaholm, fick jag en dag besök av rektorn på
              Hellidens folkhögskola, Arne Back. Han och hans fru Birgitta hade
              ett stort konstintresse och ville gärna starta en
              konstundervisning i den bara årsgamla folkhögskolan. Skulle det
              inte vara roligt att leda en teckningsgrupp frågade de mig. Med en
              stor tveksamhet blev jag så lärare i teckning på folkhögskolan.
              Det var en blyg ung man som en dag stod inför sina elever med ett
              klappande hjärta och med rädsla för att kunnandet inte skulle
              räcka till. Efter några år, samtidigt som Labyrinten har haft den
              första sommarkursen, sammanfaller så ett antal händelser på ett
              otroligt positivt sätt. Kanske är det så att latenta idëer och
              visioner hos olika människor mognar samtidigt då tiden är mogen.
              Statliga pengar kom plötsligt landets folkbildning till del i form
              av ett antal konsulenttjänster i kulturfrågor. Helliden fick en
              sådan tjänst och Rune Svensson anställdes som konstkonsulent. Arne
              Back erbjuder Labyrinten att förlägga sommarverksamheten till
              folkhögskolan för att därigenom kunna ta del av skolans resurser.
              För varje år som går växer i samhället intresset för konst och
              miljöfrågor,vilket tar sig uttryck i ökade förfrågningar om kurser
              med speciell inriktning på konst och miljö. I och med det ökade
              intresset för konst skapas det möjligheter att utveckla
              konstundervisningen på Helliden jag knyts fastare till skolan.
            </p>
            <div className="bio50">
              <Zoom className="flex flex-col">
                <img
                  style={{
                    marginBottom: '0',
                  }}
                  src={img11}
                  alt=""
                />
              </Zoom>
              <p className="bold text-sm italic">
                Arne Back, med en kopp kaffe under en paus i skolarbetet.
              </p>
            </div>
            <div className="bio50">
              <Zoom className="flex flex-col">
                <img
                  style={{
                    marginBottom: '0',
                  }}
                  src={img12}
                  alt=""
                />
              </Zoom>
              <p className="bold text-sm italic">
                Rune Svensson under ett projektarbete på Hökensås.
              </p>
            </div>
            <p>
              Efter en blygsam start, då vi känner oss för beträffande
              kursplaner, växer det fram en profilerad konsthantverkslinje på
              Helliden, en av de tidigaste inom Svensk folkhögskola. Under årets
              lopp har jag där undervisat i frihandsteckning, krokiteckning,
              målning, litografi och silversmide.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img13}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Hellidens folkhögskola.</p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="konstnarsforening">Skaraborgs konstnärsförening</h2>
            <p>
              Under 1950-talet var det nödvändigt att förstärka intresset och
              kunnandet bland konstutövarna i Skaraborg och att skapa
              möjligheter för en konstnärlig verksamhet av större omfattning än
              vad som var fallet. Bl. a. saknades övergripande organisationer
              som kunde bevaka dessa kulturfrågor i länet. Labyrinten i
              Tidaholm, Gösta Pettersson och Hjalmar Lindgren i Falköping, Lasse
              Persson, Göte Hultkvist, Ann-Mari Fagell, Göran Bendelius och Arne
              Käll från Lidköping samlades Allhelgonadagen 1959 i Siggestorp.
              Där startade vi då Skaraborgs konstnärsförening och jag valdes
              till föreningens första ordförande. När föreningen bildades låg
              länets kulturdebatt i träda. Jag vill påstå att något konstruktivt
              samarbete på länsnivå knappast förekom. Konstnärsföreningen har
              genom åren bidragit till ett ökat intresse för bildkonst och till
              en livlig kulturdebatt. Föreningens målsättning har varit att
              skapa möjligheter för människor att utveckla sitt kunnande, att
              genom ett kamratskap inspirera till fortsatt arbete samt att vara
              en brygga mellan professionella och amatöristiska kunskapsnivåer i
              samhället.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img14}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              Skaraborgs konstnärsförenings första styrelse. Bilden är tagen vid
              konstitueringen i labyrintens Siggestorp. Från vänster: Göran
              Bendelius, Ann-Mari Fagell, Lasse Persson, jag själv, Uno Nilsson
              och Åke Ryberg.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="svara">Svåra år</h2>
            <p>
              I inledningen förklarade jag att bara händelser som troligen
              påverkat mitt arbete skulle redovisas, inte något kring min
              familj. Ett undantag måste jag göra då arbetets och familjens
              situation här påverkar varandra. På våren avlider Laila i sviterna
              efter en misslyckad operation av en hjärntumör. Det hela hade
              börjat sommaren före. Tillsammans med Ollee Norell i
              Konstfrämjandet hade Rune Svensson och jag på kvällen avslutat en
              konstkurs på Helliden. När jag ganska sent kommer hem har Laila en
              fruktansvärd huvudvärk, den går inte över, så tidigt på morgonen
              åker vi in till lasarettet i Falköping. En läkare undersöker
              Laila, jag talar med honom, han säger då att huvudvärken beror på
              överansträngning och att hon kan åka hem. Jag blir arg och svarar
              honom att en sådan värk kan omöjligt bero på överansträngning och
              för övrigt har vi haft det lugnt, ni får ta hela ansvaret om hon
              inte får stanna för en riktig undersökning. Tydligen är det där
              med ansvar något obehagligt för han ändrar sig och hon läggs in
              för undersökning. Några dagar därefter åker jag tillsammans med
              min gamla mormor till Laila. Hon har då genomgått en mängd
              smärtsamma undersökningar och ligger ganska trött och talar med
              oss, en syster kommer och säger att doktorn vill tala med mig. När
              jag kommit in till honom och satt mig säger han omedelbart "er fru
              har en tumör som vi inte kan göra något åt, vi skall sända henne
              till Sahlgrenska för en uppehållande operation - kanske hon kan
              leva ett år eller mer, man vet aldrig". Vilken chock! Allt bara
              snurrade runt i huvudet, det kunde inte vara sant, vad skulle vi
              ta oss till. Vill du ha en tablett säger han. Med tabletten som
              tröst vacklar jag ut till mormor och Laila. Hur jag klarade det
              utan att dom förstod något, det begriper jag inte, allt var så
              overkligt. När jag kom hem talade jag om det för våra föräldrar,
              allt var kaotiskt. Jag hade frågat läkarna om Laila fått reda på
              sin situation, men de hade inte berättat sanningen för henne. Det
              är bäst att låta tiden gå som vanligt, fick jag som råd. Efter en
              fruktansvärt svår operation, där Lailas huvud såg ut som en blå
              fotboll, varade värken flera månader, en fruktansvärd värk, hon
              var så tålig och tapper. Så en dag är hon jublande glad ,värken är
              nästan borta, ropar hon. Det varar fram till dagarna före jul, då
              ser hon på vår julgran och tycker att ljusen är dubbla. Efter en
              kort tid, måste hon läggas in på Falköpings lasarett. Den sista
              tiden blir hon blind, stum och har mycket ont, vi kommunicerar
              genom tryckningar i handen, jag sitter och ser när de sista
              pulsslagen svagt rör sig på hennes hals. Allt är slut, jag går ut
              till bilen och kör mot Tidaholm. Det är full snöstorm både utanför
              mig och inuti, så efter någon mil kör jag av vägen ut i den hårt
              drivande snön. Jag kan inte ta mig därifrån med bilen. Det blev en
              av de längsta och hemskaste fotvandringarna jag gjort.Jag kom fram
              till en gård där jag fick låna en telefon och ringa på hjälp. Det
              värsta av allt var dock kvar för mig. Vi hade fått tre barn,
              Ann-Cathrine 8 år, Jane 5 år och Claes 3 år. Hur skulle jag kunna
              tala om att mamma hade gått bort?
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img15}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              Laila på väg till arbetet som barnsköterska, här med sin mor.
            </p>
            <p>
              Särskilt för “Anntin”, som var äldst och som bäst förstod vad som
              hänt. “Anntin” var i sitt rum, jag glömmer aldrig hur hon satt
              lutad mot väggen, alldeles blek med stora uppspärrade ögon, när
              jag sade att mamma inte fanns mer. Efter den här tidens ångest,
              sorg och den låtsaslek jag under ett år av vanmakt fått spela för
              Laila, förändrades min syn på livet. Från att ha varit en barnslig
              positiv inställning blev den nu ängsligt negativ, där jag blev
              mycket rädd för framtiden. Hur skulle jag kunna ge barnen vad de
              behövde av värme och trygghet, de hade ju förlorat så mycket. I
              mina tankar blev döden en realitet, jag började måla bilder med
              ett innehåll av ensamhet och död. Den första maj 1975 dog min far
              på Tidaholms sjukhem, just som Vulcans musikkår spelade för de
              gamla på gården utanför. Samma svaga pulsslag som Lailas upphörde
              att slå. Sjukdomen kom smygande på honom, en slags smygande
              senilitet som gjorde att han sakta och med stor ångest tappade
              kontakten med den verklighet han hade känt som sin. Det var
              omöjligt att ha honom hemma. Via Falköpings lasarett, kom han så
              småningom till Fatbygdens sjukhus och dess senilavdelning. För en
              frisk människa är det en skrämmande miljö. Att se dessa åldringar
              sitta dag ut och dag in i sina stolar och plocka i sina osynliga
              föremål, desorienterade och vilsna, ofta fastbundna. Det var
              upplevelser, skrämmande sådana, som gjorde att jag mer och mer
              började arbeta med dessa upplevelser av död och ensamhet i mina
              bilder. För att bildmässigt gestalta detta tillstånd av ensamhet
              och död var det självklart för mig att min Far i mina bilder kom
              att bli en symbol för dessa upplevelser.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img16}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              Vart går du? En svår sjukdom förde min far in i ett ångestfyllt
              rum befolkat av det okända. Ofta stod han som på bilden tafatt med
              handen slappt resignerat framsträckt. Bad han om hjälp?
              Marmorlitografi.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="kulturenslabyrinter">Kulturens labyrinter</h2>
            <p>
              Redan från starten av min konstnärliga bana har jag grubblat över
              konstens och kulturens roll i samhället. Kulturarbetarnas
              möjligheter att överleva som sådana står i relation till
              samhällets medvetna behov. Kulturen är ju den grund, från vilken
              våra värderingar och livsformer utgår och vi genom att uppleva och
              debattera ständigt förändrar samhället. Någon kulturrevolution har
              jag inga illusioner om. Kulturinnehållet måste växa sakta,
              förankrat i tradition och i gemensamma upplevelser hos ett folk.
              Kulturen måste varsamt lyftas fram från den undanskymda plats som
              den i dag har hos politiker, skolfolk och samhällsmedborgare,
              oberoende av samhällsklass. Vikten av att konstnärskåren engagerar
              sig i dessa frågor kan inte nog ofta betonas. Det är ju en av de
              kategorier som anger miljöutformningen av samhället samt svarar
              för en stor del av samhällskritiken ur vilken våra livsvärderingar
              växer fram. För bildkonstnärerna och konsthantverkarna gäller det
              att - trots skrämmande ekonomiska betingelser fortsätta att betona
              vikten av upplevelser. Det är i dag lika viktigt som att stå
              framför staffliet eller sitta vid drejskivan. Under ett hårt
              ekonomiskt tryck är det lätt att kortsiktigt värna om det egna
              reviret som det tagit lång tid att bygga upp. Min uppfattning är
              den, att om man inte understödjer amatörismen och de människor som
              likt broar kan överbrygga kultur - och kunskapsklyftor i
              samhället, då riskerar man att drunkna i den egna trånga
              egovärlden. "Gräsrötterna", kan tillsammans åstadkomma aktiviteter
              och verksamheter som verkligen kan berika kulturlivet på en ort.
              Jag vill här beskriva några händelser. En av de första aktiviteter
              som jag var med om att starta i Tidaholm var målargruppen
              Labyrinten. Genom denna förening kunde kreativa idéer och förslag
              om kulturaktiviteter slussas ut i samhället. De barriärer som i
              dag finns mellan olika yrkeskategorier, intressegrupper och
              livsåskådningar kunde luckras upp genom att vi väcker nyfikenhet
              och vilja till förståelse för det främmande. Labyrintens behov av
              ateljé var stort. När jag så kunde övertaga mina morföräldrars
              fastighet, utökade jag min ateljé och öppnade den för målarna i
              Labyrinten. Det fanns ingen konsthall i Tidaholm. Skulle någon
              konstnär ställa ut var det bara Stora Hotellet som kunde erbjuda
              en lokal. En konstförening hade funnits på orten, men den var
              avsomnad. För att kunna tillgodose det lilla konstintresse som
              fanns, började jag med hjälp av goda vänner i Labyrinten bygga en
              galleribyggnad ansluten till vår ateljé. Som förebild stod
              Louisiana i Danmark ett av Skandinaviens vackraste museer. Det
              blev ett mycket litet Louisiana, men det fungerade väl.
            </p>
            <div className="grid grid-cols-2 gap-2">
              <Zoom className="flex flex-col">
                <img
                  style={{
                    margin: 0,
                  }}
                  src={img17}
                  alt=""
                />
              </Zoom>

              <Zoom className="flex flex-col">
                <img
                  style={{
                    margin: 0,
                  }}
                  src={img18}
                  alt=""
                />
              </Zoom>

              <Zoom className="flex flex-col">
                <img
                  style={{
                    margin: 0,
                  }}
                  src={img19}
                  alt=""
                />
              </Zoom>

              <Zoom className="flex flex-col">
                <img
                  style={{
                    margin: 0,
                  }}
                  src={img20}
                  alt=""
                />
              </Zoom>
            </div>
            <p className="bold text-sm italic">
              Labyrinten, exteriörer och interiörer. På den övre högra bilden
              underhåller Hay och Topsy vid en visafton. På den undre bilden
              leder Örjan Roth-Lindberg tillsammans med Olle Ekelund
              diskussionen efter en film i filmstudion.
            </p>
            <p>
              Här bedrev Labyrinten under många år en livlig
              utställningsverksamhet. Vis- och lyrikaftnar stod också på
              programmet och under ett par år höll en musiklyssnargrupp till i
              galleriet. De första hemslöjdsutställningarna i staden
              sammanställde Labyrinten, och under några jular fram till dess att
              Tidaholms eget kommunala museum på Turbinhusön stod färdigt,
              svarade Labyrinten för utställningsverksamheten. Den idemässiga
              bakgrunden till Labyrintens utställnings - och kursverksamhet var
              denna: utställningar och konstverksamhet skulle vara lika
              naturligt att uppsöka och deltaga i som att gå till snabbköpet och
              handla för magens behov. I vår verksamhet skulle själen
              tillgodoses på ett lika självklart sätt. Vi fanns ju mitt i byn
              precis som livsmedelsaffären. Tidaholms Kulturaktiviteter
              ("Kultak") var en förening med alla kulturellt arbetande
              föreningar i Tidaholm som jag initierade. Det fanns inte någon
              kulturnämnd och inte någon annan övergripande organisation som vid
              denna tid bevakade kulturintressena i Tidaholm. På Galleri
              Labyrinten bildades därför ovan nämnda organisation, detta i ett
              försök att ena ortens organisationer i gemensamma aktiviteter och
              genom samorganisationen skapa ett övergripande intresse för
              Tidaholms kulturliv. En annan viktig målsättning var att tjäna som
              påtryckningsgrupp mot kommunen, så att en kulturnämnd tillsattes.
              Organisationen var också tänkt som en slags remissinstans för i
              kulturnämnden. Innan en budget i kommunen skulle fastställas borde
              kulturnämnden, ansåg vi, kalla till möte med denna grupp, vilken
              representerade det aktiva kulturlivet i orten. Med stöd av de
              anslagsäskanden och beskrivningar som då framkom, skulle sedan
              kulturnämnden fastslå sin politik för året. För att en verklig
              förankring av kulturnämndens politik i demokratisk anda skulle
              råda, borde en redovisning av det gångna årets verksamhet även ske
              på detta årliga möte. Tyvärr fastslogs inte denna arbetsordning
              för kulturnämnden. Stig Kvarntiden, gammal Hellidselev, blev
              kulturnämndens första ordförande efter att ha haft samma funktion
              i "Kultak". Han hade ett stort intresse för hembygd och
              kulturfrågor. Ett stort arbete hade han lagt ner på Tidaholms
              museum. Han tog kontakt med mig när kulturnämndens verksamhet
              började och frågade mig om råd för kulturarbetet i Tidaholm. Mitt
              förslag blev att tillsammans med Hellidens folkhögskola arbeta med
              återkommande kulturveckor förankrade i stadens eget kulturliv, men
              med några aktuella aktiviteter utifrån. Tanken var att
              nyfikenheten på vad personer man kände höll med, skulle ge nya
              upplevelser och ge lust att prövakreativa aktiviteter.
              Målsättningen var en tolerant, gemensam kultur, tillgänglig för
              alla. Tråkigt nog fanns det inte tillräckligt med pengar för att
              sedan under året följa upp intresset som skapades under veckorna.
              En unik verksamhet blev kvar - en årlig sommarverksamhet startade
              på Turbinhusön och sedermera även i stadens grafikverkstad.
              Hellidseleverna som under de första kulturveckorna lade ner ett
              jättearbete, fick förtroendet av kommunen att under
              sommarnmånaderna arbeta med konsthantverk och grafiska tekniker
              och ge en värdefull information om hur olika konsthantverkare och
              grafiker arbetar så att allmänheten rätt skall kunna uppskatta
              deras verk. På få platser i Sverige kan man idag under sommaren i
              öppna verkstäder få ta del av såväl keramikhantverk, silversmide
              och textiltryck som koppargrafiska och litografiska tryckmetoder.
            </p>
            <div className="bio50">
              <Zoom className="flex flex-col">
                <img
                  style={{
                    marginBottom: '0',
                  }}
                  src={img21}
                  alt=""
                />
              </Zoom>
            </div>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="litografiskaverkstaden">Litografiska verkstaden</h2>
            <p>
              l en förteckning över de i Sverige förekommande
              kollektivverkstäderna vid årsskiftet 1978-79 finns i boken "Grafik
              för publik" av Sixten Haage och Sven-Robert Lundqvist följande
              angivet: TIDAHOLM, Labyrinten, Silvergatan 2,522 00 Tidaholm,
              (Karl-Åke Nyström), litografi, startade 1958, grafikverkstaden
              installerades 1962. Tittar man på den översikt som boken ger,
              finner man att bara Gävle grafiker har en äldre verkstad.
              Tidaholms verkstad var i funktion samma år som Grafiska
              sällskapets i Stockholm. Hur kom det sig att lilla Tidaholm kunde
              ha en litoverkstad vid denna tid, det kan man fråga sig. Svaret är
              inte så svårt att ge. Jag hade ju arbetat på Vulcans litografiska
              tryckeri. Här hade jag lärt känna de duktiga tryckarna och deras
              chefer. När jag återvände till Tidaholm tog jag kontakt med
              tryckeriet igen. Där fick jag hjälp med tryckningen av några
              litografiska blad. Under arbetet med dessa blir det klart att
              tryckeriet skall avvecklas, tändsticksfabrikens behov av etiketter
              kommer att klaras vid bolagets fabrik i Jönköping. Detta hade
              stått klart för de anställda en längre tid. Det rådde inom den
              grafiska branschen en snabb utveckling mot fotografiska
              överföringsmetoder av original. Detta var en revolution som
              medförde att de tryckerier som skulle överleva på sikt var
              tvingade att modernisera. Tändstickstrusten gjorde inte detta, och
              då förstod man att en nedläggning skulle komma. När jag hörde
              talas om detta, bad jag disponent Thunberg om lov att få övertaga
              några s.k. övertryckspressar, en plåtkorneringsmaskin och ett
              stort antal litografiska stenar. De flesta tryckpressarna skulle
              säljas som skrot och stenarna skulle köras till soptippen. För mig
              var det något fruktansvärt - att förstöra en fullt användbar
              utrustning. Men allt berodde på tilltron till den nya tekniken,
              som höll på att växa fram på tryckerierna. Tragiskt för
              konstnärerna var att dessa ej var medvetna om att väldiga resurser
              rycktes undan dem. De utrustningar som skrotades och slogs sönder
              vid denna tid kunde betytt mycket för konstnärerna i Sverige. Då
              mina år som litograf gjort mig medveten om de möjligheter som
              utrustningen gav, var det inte så konstigt att jag bevakade
              händelserna och även fick vad jag bad om, "det var ju värdelöst".
              En man som inte tyckte så var materialförvaltaren på Vulcan,
              Bertil Agn&amp;us. Han var väl insatt i de litografiska teknikerna
              och visste vad man kunde göra med dessa stenar och pressar. Han
              hade a-betat på Vulcan sedan barndomen och upplevt Kffigerkraschen
              på 30-talet. För Tidaholms arbetare innebar den svåra år som
              gjorde att människorna var tvungna att spara och ta till vara de
              tillgångar man hade omkring sig. Gamla tidaholmare talar om
              "Tidaholms-andan" - då menar man att hålla i hop och hjälpa
              varandra. Bertil Agnerus var nog präglad av den andan, han hjälpte
              mig komma över en förnämlig utrustning.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img22}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              I den gamla ateljén. "Tryckar Olle" och jag begrundar en
              invalsning.
            </p>
            <p>
              Det lilla tryckeriet stod så småningom klart i min ateljé en av
              pressarna hade dessförinnan stått en tid i Labyrintens Siggestorp.
              Som tur var hade jag stor hjälp av "Tryckar Olle" de första åren,
              han hade ju all önskvärd erfarenhet och kunskap. 1962 började vi
              alltså den grafiska verksamheten. Min ateljé var mycket större än
              vad jag själv behövde, varför jag upplät den till Labyrinten och
              intresserade konstnärer. Varje tisdag och torsdag samlades
              labyrintens medlemmar här och målade. När Hellidens folkhögskolas
              konsthantverkslinje startade, fanns inte lämpliga lokaler i
              skolan. Därför ägde den litografiska undervisningen rum nere på
              "Labyrinten" - vi började kalla ateljén så. Men det var inte bara
              litografitekniken som Helliden under de här åren förlade ner till
              Labyrinten, även krokiteckningen och diskussionerna efter
              filmvisningarna på biografen Röda Kvarn. Då samlades Hellidens
              filmstudio här, det var Örjan Roth Lindberg och Olle Ekelund som
              på ett engagerat och inspirerande sätt ledde debatterna. Genom
              åren blev det cirka femtio nybörjarkurser som jag höll för skolans
              räkning. På initiativ av konstnären Gunnar Wärn kom jag även att
              leda två AMS-kurser i litografi. Tyvärr började jag de sista åren
              under kurserna få huvudvärk och på kvällarna yrsel.Verkstaden
              saknade bra ventilationsmöjligheter. Därför trodde jag att den
              kollektiva verksamheten skulle tvingas att upphöra. Riksdagsman
              Lars Hjertén som då var ordförande i landstingets och Tidaholms
              kulturnämnder, engagerade sig då mina svårigheter med att
              fortsätta den grafiska verksamheten. Han fann att Vulcans gamla
              ritkontorsbyggnad var till stora delar ledig och att huset skulle
              vara lämpligt för våra behov. Efter en del förhandlingar fick
              Hellidens folkhögskola i samarbete med mig övertaga lokalerna, och
              utrustningen från Labyrinten flyttades dit. Vid planeringen av
              verkstaden hade jag stor hjälp av Erik Holgerson. Han arbetar som
              teknisk lärare på Valands konsthögskola och höll vid denna tid på
              med att planera en ombyggnad av Valands grafiska verkstad. De
              första skisserna på planlösningen av Tidaholmsverkstaden och dess
              utrustning gjordes på Eriks köksbord i Göteborg. Här måste jag
              berätta hur det gick till: När jag en dag bilade ner till Göteborg
              satte Erik som har ett stort kunnande och intresse av litografi
              genast igång att skissa på planlösningar av verkstaden. När jag
              kom lovade han att bjuda på frukost, men det gick en timma, två
              timmar och många fler innan han kom ihåg grytan som stod övergiven
              på spisen. Så är det med Erik! Kommer samtalet att kretsa kring
              grafik, då glömmer han tid och rum, den andliga spisen är ju
              viktigare, bättre rådgivare kunde vi inte fått. Den verkstad som
              nu är i funktion i Tidaholm har en av landets största samlingar av
              stora litografiska stenar, den har två handpressar för stentryck,
              en provtryckspress för offset, vidare finns stenslipningsrum samt
              en plåtkorneringsmaskin och kopieringsbox för ritfilmslitografier.
              Under de första årens verksamhet i de nya lokalerna hade Erik och
              jag tillsammans en litografikurs i AMS regi för yrkeskonstnärer.
              Två kurser för Skaraborgs konstnärsförening med mig som handledare
              genomfördes också. Under några månader på hösten -83 startade Erik
              och jag ett utvecklingsarbete. Erik ringde en dag och talade om
              att han prövat en marmorsten som tryckbärare och hade fått fram
              bra tryck. Vi bestämde att han skulle komma upp och att vi
              tillsammans skulle pröva några större marmorstenar. Efter en
              rundtur hos stenhuggerierna i trakten hade jag några f.d.
              diskbänkskivor med mig hem att arbeta på. Erik kom upp till
              Tidaholm och vi började ett intensivt stänkande och skvättande på
              våra marmorskivor, vi var intresserade av möjligheterna att få
              fram och bevara laverade gråtoner, bl.a. därför vårt
              spontanistiska arbetssätt. Resultatet blev över förväntan, vi
              kände oss nästan som två Senefeldrar när vi betraktade trycken.
              Här öppnades nu nya vägar för konstnärer som inte äger eller kan
              komma över de dyrbara Sollenhofenstenarna. Willem de Kooning hade
              utställning i Stockholm - våra spontanistiska bilder påminde om
              hans verk. Därför signerade vi bilderna med "de Kunniga".
              Folkhögskolan har under de här åren fortsatt med sina
              nybörjarkurser i olika grafiska tekniker. Som komplettering av
              verkstaden har skolan flyttat ner sin koppargrafiska utrustning
              till de nya lokalerna och har på så sätt kunnat koncentrera
              undervisningen. Genom alla år har konstnärinnan Barbro Porslund
              lett undervisningen i koppargrafik. Redan på tidigt 60-tal kom
              frågan om grafisk verkstad upp i landstingets kulturnämnd.
              Landstinget hade då en verkstadskola i Tidaholm. Tankegången var
              då att i samarbete med Helliden verkstadskolan skulle starta
              länets konstgrafiska verkstad i en lokal där AGA förut tillverkat
              acytelengas. Tyvärr blev det inget av den gången. Vi körde ner en
              av våra pressar och ställde utanför byggnaden, där stod den ganska
              länge i väntan på att några beslut skulle tagas. Några sådana togs
              inte, så vi åkte ner för att hämta upp vår "dyrgrip" till skolan
              igen. Men vi kom för sent, pressen var borta och vi har aldrig
              sett till den sen dess. Om jag skulle skämta bort det hela kunde
              jag kanske säga "Den står och trycker någon annanstans"! Frågan om
              grafisk verkstad kom på 70-talet åter upp i landstingets
              kulturnämnd. Där tillsatte man en arbetsgrupp som skulle se över
              möjligheterna att förlägga en grafisk verkstad till Axevalla
              folkhögskola. Gruppen bestod av Bengt Winberg, Berndt Jutemar och
              mig men efter en utredning fann vi att Axevalla inte var lämpligt
              att föreslå som plats för verkstaden. Kulturnämnden besökte då
              bl.a. Helliden och uttalade sig för Hellidens folkhögskola som
              lämplig huvudman för verkstaden, då man menar att här finns
              traditionen och kunnandet. Men vi kunde inte gripa tillfället i
              flykten, skolan hade inte några lämpliga lokaler och vi måste se
              tiden an. I Skövde får man så möjlighet att med Berndt Jutemars
              koppargrafiska verkstadsutrustning som bas starta en koppargrafisk
              kollektivverkstad med stöd av landstinget. Vid samtal med Berndt
              och med Lennart Sixten, landstingets kulturchef, så är vi överens
              om att dela på den grafiska verksamheten. Skövde med sin
              koppargrafiska tradition ansvarar för den tekniken. Tidaholm med
              sin långa litografiska tradition, sen 1800-talet, svarar för
              litografiverkstaden. Det lät förnuftigt, men så blev det inte. Av
              någon anledning så glöms samtalen bort. När Tidaholms
              konstgrafiska verkstad går in till landstinget med begäran om
              samma utlovade stöd som Skövdeverkstaden fått, går ansökan på
              remiss till KRO-sektionen i länet. Denna säger sig inte kunna
              acceptera Helliden som huvudman utan kräver huvudmannaskapet.
              Situationen var mycket sorglig, här erbjöds en konstnärskår, där
              jag själv är medlem, att utnyttja en av landets bäst utrustade
              litografiska verkstäder och så tackar man nej. Motiveringen
              upplevde vi , som arbetat i åratal med att bredda ett kunnande i
              länet, som mycket kryptisk. Att inte lita på folkhögskolan, att
              inte acceptera platser i verkstadsstyrelsen, en styrelse där det
              nu sitter två lärare från Valand, förre ordföranden för KRO i
              Sverige m.fl. Ännu underligare framstår det när man vet att skolan
              endast begärde veckor per år för sin verksamhet - resten av tiden
              erbjöds de yrkesverksamma konstnärerna att förfoga över. Sveriges
              näst äldsta kollektivverkstad är idag mer levande än någonsing.
              Jag hoppas nu bara att informationen om vår verkstad skall nå
              konstnärerna i vårt land, så att de kan ta till vara de resurser
              som står till deras förfogande.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img23}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              Erik Holgersson, vid det första större provtrycket från
              marmorsten, som vi gjorde i grafiska verkstaden.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="fargpastan">Sätt färg på stan!</h2>
            <p>
              Utställningsverksamheten som Labyrinten bedrivit under ett antal
              år hade svårt att nå ut till någon större publik. I
              musiklyssnargruppen på galleriet fanns Harry Mattson, ansvarig för
              forskningen kring plasttillverkningen på Tidaholmsverken. Han
              prövade att i acrylplast gjuta in grafiska blad åt mig. Vi fann
              här en ide som om den kunde förverkligas skulle betyda en
              förändring av konstnärernas situation och av vår yttre miljö.
              Utställningar i traditionella miljöer nådde bara de "troende",
              hade vi funnit. Vi kunde nu med ingjutningarnas hjälp placera våra
              bilder ute i stadsmiljön, där folk vistades. Kommunerna kunde köpa
              bilder av konstnärer, plasta in dem och sedan göra utställningar
              på pelarsystem. På sommartid kunde det visas aktuella
              debattutställningar och när den grå hösten kom, kunde det vara på
              sin plats med färgrika estetiskt sköna bilder. Bäst av allt:
              konstnärerna skulle här få ett enormt stort nytt arbetsfält med
              stat, landsting och kommuner som beställare. Konstverken skulle
              efter "pelartiden" placeras på artotek i biblioteken. Härifrån
              skulle sjukhus, institutioner och allmänheten kunna låna bilderna
              under vissa perioder. I hemmen kunde på så sätt uppstå små privata
              gallerier. Resultatet för konstnärskåren skulle bli en större
              social trygghet. På en målarkväll i Labyrinten skisserar jag idén
              för labyrint-gruppen, den beslutar utforma ett förslag och sända
              det till kommunen. Förslaget blev att kring ån Tidan, som rinner
              genom staden, i den befintliga parkanläggningen, mitt emot
              Turbinhusön ett pelarsy-tem med konst skulle placeras in. En
              sagogrotta i armerad plast från Tidaholmsverken skulle byggas i
              anslutning till pelarna. Barnen i stadens skolor skulle svara för
              sagomålningar vilka skulle plastas in i grottans väggar. För
              barnens föräldrar skulle vi bygga ett "pedagogiskt rum", ja vi
              kallade det så. Stora acrylglasskivor skulle i ett kvadratiskt rum
              monteras upp som ett ramverk, på ett sådant sätt att skivorna blev
              skjutbara. Varje vägg skulle innehålla minst två skivor. En
              ofärgad skiva, där landskapets konturer skulle målas in när skivan
              var i en viss position. Genom att förändra läget kunde man sedan
              komponera om rummet. Satt man då inne i rummet förändrades
              landskapet utanför, blev allt mer en abstrakt bild, genom att de
              uppritade linjernas läge i förhållande till naturen ändrades.
              Slutligen skulle man med väggens andra något mindre skiva, som var
              färgad men transparent, åstadkomma en riktad färgbetoning av
              kompositionen. Under de år jag arbetat med konst är det denna idé,
              som verkligen borde fått en chans. Tyvärr förstod inte stadens
              fäder vilka utvecklingsmöjligheter som låg förborgade här
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img24}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              Labyrintens "Vi äro musikanter", vid entrén på länsutställningen R
              68 i Skövde.
            </p>
            <p>
              Förståelsen var inte stor från stadens sida, för vad vi höll på
              med. Jag skall sluta det här avsnittet med att berätta vad som
              hände med en utsmyckning Labyrinten skänkte till stadens
              centralskola. För att spara pengar hade man byggt en mycket
              spartansk skola - bara asfalt och betong. Det är bakgrunden. På
              den stora länsutställningen R 68 i Skövde hade Labyrinten vid
              entrén placerat en monumentalskulptur i armerad plast. Den var
              cirka tre meter i diameter. Vi kallade den "Vi äro musikanter".
              Motivet var en abstraktion i form av runda klot som svävade ut
              likt dansarna i folkleken. När utställningen var slut skänkte vi
              skulpturen till kommunen för att den skulle placeras på
              Centralskolans trista gård. I väntan på att den skulle sättas på
              plats stod den i stadens förråd - antog vi. Calle Wahl, KRO:s
              dåvarande ombudsman, var på besök, och jag berättade för honom om
              skulpturen. Han blev intresserad så vi åkte för att titta på
              densamma. Men det fanns ingen skulptur att se på. Snopen får jag
              tag i en kommunalarbetare som jag frågar: var tusan är skulpturen?
              "Dän", säger han, "dän har vi skeckat te renhållningsverket"!
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="ljuset">Resor till ljuset</h2>
            <p>
              Behovet att komma ut från vår lilla stad kom att växa sig starkt.
              Jag hade läst om van Goghs längtan till söderns sol, och det hade
              etsat sig fast i mitt minne. Problemet för mig var hur skulle jag
              kunna finansiera en målarresa. Det kändes besvärande att fresta på
              familjens ekonomi. 1963 hade jag gift om mig med Sickan
              Danielsson, vi hade umgåtts dagligen och blivit kära. Hon arbetade
              i min affär som affärsbiträde, hon var intresserad av teckning och
              visade lovande anlag för silversmide. Anlagen hade hon säkerligen
              ärvt från sin far Allan, han var en mycket skicklig och noggrann
              möbelsnickare. När så Labyrinten gav ut en mapp med grafiska blad,
              kallad "Vår Stad", hade föreningen plötsligt pengar till en
              målarresa. Sickan förstod mitt behov av att komma ut och studera,
              så en dag i slutet av april tog "Labyrintarna" tåget till Toscana
              i Italien. När vi kom ner till Florens, var det som en vacker dröm
              för mig. Vilket ljus! - Då förstod jag vad van Gogh hade menat med
              sitt tal om ljus och rena lysande färger. Husens lysande rödgula
              tak, de vita väggarnas rödblåa ljus och slagskuggornas mättade
              blåa färgtoner - alla färger klart lysande. Vilken målarglädje!
              Denna färg- och ljus upplevelse har legat till grund för mitt
              sökande efter det "rena" måleriet allt sedan dess. En överraskning
              väntade oss när vi kom ner till Riva del Sole. Eva Jacobsson,
              målarinna, och hennes man Ingel, folkhögskollärare, trubadur och
              länsbildningskonsulent, stod och välkomnade oss. Vi hade berättat
              om vår resa för dem, och dessa underbara vänner hade i hemlighet
              bilat ner till Italien för att tillsammans med oss måla och
              studera. Detta gav oss möjligheter att med privat bil göra
              museibesök i bl.a. Rom. Med sakkunnig ledning av makarna Jacobsson
              kom jag här i kontakt med antikens och renässansens tidsepoker.
              Upplevelserna gav mig ett levande intresse av gångna tiders
              kulturer. Allt sedan min första resa till Italien, har jag läst
              allt vad jag stött på om dessa tidsepoker.imina bokhyllor finns nu
              mängder med litteratur. Det har för mig genom åren blivit många
              resor. Det är framförallt två andra resor som har haft avgörande
              betydelse för mig, vid sökandet efter ljuset i mitt måleri. 1967 i
              februari reste vi målare i Labyrinten till Teneriffa. Vi hade
              kommit över en billig resa. Ett propellerplan som tog nästan tio
              timmar på sig, flög ner oss till los Rodeos. När vi på morgonen
              stiger ur planet kommer vi rakt in i sommarens värme, borta är
              snöovädret som rasade hemma. Allt önskvärt finns här-värme, ljus
              och en prunkande färgsprakande natur -då är målarglädjen nära! Det
              blev ett intensivt målande från åtta på morgonen till fem på
              kvällen, därefter siesta några timmar, sedan mat på någon trevlig
              restaurang, som inte var för dyr. Då taxibilarnas priser var låga,
              kunde vi hyra en sådan som varje dag tog oss ut till öns olika
              delar. Vi anlitade samma chaufför under hela vistelsen på ön, hans
              bil stod alltid parkerad nere vid hamnen när han inte körde oss.
              En dag bilade vi ut till Mercedesdalen och jag fick mig till livs
              en av mina största skönhetsupplevelser. Den började egentligen
              kvällen före, då jag genom ett misstag kom i kontakt med
              samhällets negativa sidor. De upplevelserna kom att förstärka den
              naturupplevelse som följde. Det började så här: Efter att ha målat
              hela dagen, uppsökte vi vår chaufför,han stod parkerad på sin
              vanliga plats. Vi var hungriga och frågade om han kände till något
              matställe med lite musik och underhållning. "Här i Poerto de la
              Cruz finns ingenting, men i Santa Cruz vet jag ett toppenställe,
              jag kör er dit", sade han. När vi kommer fram till Santa Cruz kör
              han ner till hamn-området, det var mörka trånga gränder. Utanför
              en dörröppning avskärmad med ett pärldraperi, stannade han bilen
              och sade, "här är det". Vi hade under tiden blivit oroliga och
              börjat förstå att han måste ha missuppfattat vårt önskemål. Men
              eftersom vi var vä-uppfostrade svenskar, ville vi inte göra vår
              vän chauffören ledsen, utan vi går fram till dörröppningen och
              viker undan pärldraperiet. Det tog en stund innan jag såg något,
              rummet vi kom in i låg i halvmörker. När ögonen vant sig, såg jag
              ett stort rum fyllt med bord och stolar runt ett dansgolv. Men det
              fanns inte en levande själ i rummet. Förvirrade satte vi oss kring
              ett bord, fyra musiker kom in och började spela. En servitris kom,
              hon frågade vad vi ville dricka. Då kom den största chocken, för
              plötsligt kom en mängd kvinnor fram till vårt bord och frågade om
              vi ville dansa. Dessa kvinnor utgjorde ett tvärsnitt av jordens
              olikfärgade människor, gula, svarta och vita. Förvirringen hos oss
              småstadsbor var total, när vi sakta drog oss mot dörren. Lika
              förvirrad var chauffören när vi kom tillbaka så snabbt. Vi
              förklarade att det var mat vi behövde och inte något annat. Han
              körde oss då till en nattklubb där vi bjöds på god kanarisk mat
              och där mycket duktiga musiker och dansare från trakten bjöd på
              kanarisk folklore. Dagen efter stod vi och såg ut över en av de
              vackraste dalar jag sett, Los Mercedesdalen. Det var väl så att
              efter att ha sett den slitna hemska bordell-världen framstod den
              rena orörda naturen ännu vackrare än vanligt. Vad såg jag? Jo - en
              vad jag tyckte oändligt stor grön dal med små idylliska lantgårdar
              i vitt med rödgula tak. Evert Taube har diktat om Toscanas kullar
              i visan om Pierina. Där beskriver han den pastorala idyllen i
              landskapet, samma stämmning rådde här. Vi hörde ljud av boskap
              långt, långt borta, den klara rena luften var fylld av värme och i
              horisonten lyste Atlanten klarblå. Det var en kuperad dal, de
              gröna ängderna fick en underbar grön rytm av kullarnas och
              dalarnas form. Vi gick ut ur bilen. Helt betagna sjönk vi ned på
              en äng översållad av blå blommor. I mitt huvud ljöd Glocks: "På de
              saligas ängder”. Denna dal blev en symbol för frihet och lycka.
              Färgupplevelsen och landskapets rytm låg nära mina strävanden
              efter det “rena måleriet". Jag korn under flera år att måla på
              teman från denna dal — arbetsnamnet var för mig självklart: MIN
              GRÖNA DAL. På min tredje viktiga resa kom jag till Röda havet. En
              resebyrå beställde en grafikmapp av mig, där bilderna skulle
              berätta om bibelns länder. För att klara det uppdraget flög jag
              och min arbetskamrat Rune Svensson till Amman i Jordanien.
              Därifrån till Israel -och efter studier där tillbaka till
              Jordanien igen. Kyrkoherde Bengt Åke Ögren i Tidaholm hade
              berättat för mig om den rosa staden nu, skulle jag dit. Petra är
              det officiella namnet på denna fascinerande hemlighetsfulla stad.
              En gång i tiden Nabateernas huvudstad. Efter många timmars bilfärd
              genom ett ökenlandskap kom vi fram till denna, för inte så många
              år sedan okända plats. I anslutning till en gravkammare från
              Nabateernas tid, fanns det ett mycket primitivt "Rasthaus" där vi
              tog in. På kvällen satt vi i gravkammaren och åt kyckling - den
              enda rätt som serverades på stället - det kändes overkligt, nästan
              kusligt. Så kom natten med en isig kyla, aldrig har jag frusit så
              mycket som den natten. Fast jag tog på mig alla de kläder som
              fanns, skakade jag av köld, och det var samma sak för Rune. Det
              var bara ett att göra, vid fyratiden på morgonen gick vi ut och
              motionerade. Ökenvinden stack som isnalar genom kläderna när vi
              irrade fram och tillbaka mellan kullarna. Det var underbart när
              solen äntligen orkade över bergsryggarna som omgav oss och började
              värma våra stelfrusna trötta kroppar. Fram på dagen fick vi hyra
              hästar av platsens ovänliga befolkning och vi red in i en stor
              smal ravin där himlen i bland var som ett blått smalt streck högt
              högt upp. I Ravinens röda och orange färgade väggar var stora
              byggnader inhuggna. Efter en lång ritt, kom vi fram till en större
              öppen plats i Ravinen. I bergväggens fond var en ståtlig
              palatsliknande byggnad uthuggen - den var helt fantastisk, med
              sina monumentala kolonner. Ravinen - färgerna - formerna - kölden
              blev minnesbilder som har betytt mycket för mig. En episod kunde
              slutat illa för mig. På eftermiddagen begav vi oss ut på en
              fotvandring ,vi skulle teckna. Jag hade stukat foten och den
              steniga terrängen gjorde det svårt att gå. Efter att ha gått ett
              stycke insåg jag att det var omöjligt för mig att fortsätta. Rune
              fortsatte därför vandringen själv, medan jag stannade ett stycke
              från vägen för att teckna. Försjunken i min bild märkte jag inte
              att tre män närmade sig, de var klädda i arabiska kläder och hade
              stora knivar hängande i bälten runt midjorna. De såg aggresiva ut
              när jag fick syn på dem, och jag undrade, hur skall detta sluta.
              Männen uppträdde mycket hotfullt mot mig, jag förstod att de ville
              ha pengar. Jag slog ut med armarna och försökte förklara att jag
              inte hade några. De började knuffa på mig. Jag stod med ryggen mot
              en klippvägg så de kunde inte komma åt mig bakifrån. Jag lyckades
              hålla dem ifrån mig. Men så fick någon ett fast tag i min ena arm.
              Där nådde han min klocka och slet av den från armen.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img25}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              Rune på hästryggen, redo för en tur på den gamla karavanvägen
              mellan Kairo och Bagdad.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img26}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Petras ruiner</p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img27}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              Palmer och båtar. Från båtvarven nere vid Aqabaviken.
            </p>
            <p>
              I den stunden var jag illa ute men just då - hörde jag någon komma
              på stigen en bit bort. “My friends” - ropade jag - då blev mina
              rövare rädda och rusade bort. Det var på mycket skakiga ben som
              jag sedan berättade för Rune om händelsen. Vi hade inte tagit reda
              på att området ansågs så pass farligt att turister varnades för
              att övernatta där. Nästa anhalt på vår resa var Aqaba vid Röda
              Havet. Aqaba var en “sovande” stad när vi kom dit, få hotell och
              följaktligen ett ringa antal turister. Min resebyrå i Jordanien
              hade hyrt in oss på ortens lyxhotell - Holiday Inn - och det var
              verkligen lyxigt. Jag fann miljön mycket inspirerande med de gamla
              husen nere vid havet omgivna av palmer. Primitiva båtvarv med
              båtskrov staplade efter stranden, och i närheten små enkla
              fiskrestauranger som serverade en fantastisk god fisk. Med - det
              var de omgivande bergens och vattnets färger - med det skimrande
              ljuset som inspirerade mig allra mest. Ljuset med sina ljusa
              lysande färger var precis det jag drömt om - van Goghs och Aguelis
              ljus fanns runt omkring mig. Det var underbart att få sitta på
              restaurangernas terraser vid havet och med pastellkritornas hjälp
              försöka återskapa detta underbara ljus som flödade och tände röda
              eldar på havet och bergen. Idag många år efteråt finns fortfarande
              Aqubas ljus och färger i mitt huvud, och ständigt går jag tillbaka
              till mina första skisser - de får ligga till grund för nya
              målningar. Röda havet har blivit den största måleriska
              upplevelsen, hitintills i mitt liv. Tre gånger har jag återvänt
              för att på nytt få uppleva detta himmerlska ljus. På kvällen innan
              vi skulle åka fick vi beskedet att en taxi skulle hämta oss
              klockan 6 på morgonen. Vi var inbokade på ett flygplan till Aqaba
              klockan 8. Vid förmiddagen beställde vi in fisk, jag var hungrig
              och svalde några stora bitar, innan jag hann känna en otrevlig
              smak i munnen. “Ät inte fisken” sade jag till Rune, som tog det
              lugnare och följde mitt råd. På natten blev jag dålig, jag fick
              mest hänga på toaletten. När vi skulle resa på morgonen orkade jag
              knappt stå upp, minuterna gick men någon taxi kom det inte - vad
              skulle vi göra. Jag satt bara på marken och mådde pyton. Turligt
              nog var Rune vital och fick tag i en något begagnad Mercedesbil
              med en arabklädd chaufför vid ratten. Han körde oss till
              flygfältet som verkade vara ett millitärflygfält. Några millitärer
              fick noggrant igenom våra resväskor, varefter de visade oss till
              incheckningen. Där stod en man och stirrade ilsket på oss, “vad
              vill ni” frågade han. Vi förklarade då att vi bokat plats på
              Amman-planet, “det är försent, vi stängde för fem minuter sedan”
              sade han, med ett retligt leende i mungipan. Vi kom inte med
              planet, det gick inte att övertala mannen. Vi lyckades få tillbaka
              våra resväskor från millitärerna som tagit hand om dem. Med väskan
              i hand vacklade jag ut till vår chaufför som var kvar på
              flygplatsen. Efter ett långt ackorderande kom vi överens om priset
              för körningen genom öknen till Amman. Det tog sex timmar, hela
              tiden låg jag i baksätet och mådde pyton. Rune satt i framsätet
              hos chauffören. På hela tiden sades inte ett ord, det var bara
              radions entoniga arabiska musik som bröt tystnaden. När vi
              äntligen ärframme får vi uppleva resans verkliga påfrestning. Vår
              agent känner att han på något sätt måste rätta till misstaget med
              den missade flygningen. När vi kommer till vårt hotell, står därör
              en guide med taxi och väntar på oss. Han har fått order att ta oss
              med på en mycket noggran sightseeing av Amman. Jag har en känsla
              av att Jordaniens taxichaufförer och guider, efter bekantskapen
              med Rune och mig, kommer hävda att några tystare, tråkigare och
              ovänligare folk än svenskar finns det inte.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img28}
                alt=""
              />
            </Zoom>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img29}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Mot ljuset. Litografi.</p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="kreta">Kreta - Knossos</h2>
            <p>
              Kreta med sin mytologi har blivit bakgrundsmiljön där jag i mitt
              bildskapande placerar upplevelser om död och ensamhet. Framförallt
              är det kung Minos palats i Knossos som lockat min fantasi. Sir
              Arthur Evans rekonstruktion, på gott och ont, av palatsbyggnaden
              har vid varje besök inspirerat mig att göra nya bilder med
              ovanstående tema. Det är en oerhört stark dramatisk och grafisk
              upplevelse att gå nere i de mörka rummen och se när de smala
              ljusstrålarna, från högt belägna öppningar, sveper ner över
              rummens tunga pelare och plötsligt naglar fast en människa i
              rummet. Ett ögonblick då ljuset för mig tillbaka till kung Minos
              tid - det kunde ha varit han som stått där, exponerad i evighetens
              kamera. Under mina resor hit har jag haft förmånen att lära känna
              författaren Finn Ståhl. Han har med sina stora kunskaper och sin
              berättarförmåga riktat mina ögon mot Knossosmyternas förtrollade
              värld. Kretaresor har för mig inte bara varit Knossos. Hela ön är
              inspirerande. Med sina berg och dalar och dess befolkning, som
              till för några år sedan lyckats bevara sin kultur ovanligt intakt.
              En stor upplevelse är att resa med buss från Heraklion och in i
              landet över bergen på små slingrande vägar, där hjärtat i bland
              far upp i halsgropen. Efter en mängd kurvor kommer man upp på
              bergstoppar som ger ögat allt av vackra utsikter med gröna dalar
              och blåa berg. Bilden blir fullständig med den kretensiska musiken
              som strömmar ut från bussens radio. Denna musik hör så intimt
              samman med landskapet och människorna. Det är svårt att tänka sig
              situationen i något annat västeuropeiskt land. En buss eller
              bilfärd med skvalradian fylld av traditionell folkmusik. På
              kretensiska bussturer har jag med hjälp av natur - musik och
              människor upplevt de gamla grekiska herdarnas legendariska frihet.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="silver">Morfars silvertrådar</h2>
            <p>
              Första gången som materialet silver snuddade vid mitt liv, var vid
              min morfars dödsbädd. Han låg hemma i mormors och morfars finrum,
              han var mycket sjuk och hade fått smärtstillande medel. Barnen
              hade varit inne hos honom och han hade sagt adjö till dem, och
              förmanat dem att ta hand om mormor. Han var inte frälst men trodde
              på Gud, därför var han inte rädd för döden utan övertygad om att
              alla skulle mötas igen. Hela tiden detta avsked pågick satt jag i
              köket och var rädd, döden hade jag aldrig varit nära. Alla var så
              ledsna, så när mormor kom och hämtade mig var jag hemskt rädd. Men
              morfar såg inte farlig ut, han log mot mig och sade att jag skulle
              sätta mig på sängkanten hos honom. Han började prata med mig. Så
              plötsligt säger han "SILL STILL! DET GÅR SILVERTRÅDAR TILL DITT
              HAR", jag blev rädd för så brukade inte morfar prata. Nu yrar far,
              sade barnen. Aldrig trodde jag att det skulle bli en slags
              profetia, men jag kom att utbilda mig till silversmed. Genom åren
              har jag utfört mängder av silverarbeten i bl.a. kyrkor, något som
              säkert skulle gjort min morfar glad. Ett av de bättre kyrkliga
              arbeten som jag utfört är nattvardssilvret i Sankt Lucaskyrkan vid
              Billingehus i Skövde. Paradoxalt nog kom jag genom en mycket
              negativ upplevelse i denna kyrka att tappa allt mitt intresse för
              sakrala arbeten. Jag hade fått förfrågningar om att göra ett
              krucifix till kyrkan, efter det lyckade arbetet med
              nattvardssilvret. Uppdraget fångade mig. Kyrkorummet är underbart
              och utsikten genom kyrkans stora fönster mot öster ger en så
              vacker meditativ känsla. Problemet var dock att arkitekten
              Hans-Erland Heineman hade placerat ett kors i fönstrets mitt som
              en del av konstruktionen. Ett kors i traditionell form på altaret
              skulle kännas som en upprepning ansåg jag.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img30}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">
              "Mundus". Silversmide inspirerat av Kullabergs klippor.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img31}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Vårdträd.</p>
            <p>
              Efter mycket ritande och modellbygge hade jag så kommit fram till
              en lösning som jag var övertygad om skulle berika kyrkorummet
              samtidigt som den var nyskapande som silversmide. Jag presenterade
              förslaget i kyrkan för den kyrkliga syföreningen och kyrkorådet.
              Men mina motivationer för formgivningen accepterades inte alls,
              man undrade om jag inte kunde göra ett något mindre kors som var
              mer vanligt. Efter att i en timma hört inlägg hit och dit om
              formlösningar var jag utled på det hela. Efter vad jag minns, bad
              jag att få slippa ytterligare diskussioner. Jag fortsatte: Ett
              uppdrag av detta slag är i mycket stor utsträckning en fråga om
              förtroende. I framtiden är det tjag som får stå för arbetets
              kvaliteter och inte Ni, därför ber jag att få tacka för mig. Efter
              denna händelse har jag inte varit intresserad av några kyrkliga
              uppdrag. På somrarna i början av 60-talet gick målarresorna till
              Arild i Skåne. Kullabergs klipplandskap, bokskogarna och
              slättlandskapet, allt var intressant och lockade till arbete. Mest
              av allt fångade klippformationerna mitt intresse, men jag hade
              väldiga svårigheter med att gestalta vad jag såg. En dag var det
              fel på min bil, jag var tvungen att uppsöka Mölles bilverkstad,
              där såg jag några gamla aluminiumplåtar ligga och då fick jag en
              ide. Egon, firmans ägare, gav mej plåtbitarna och av Walle,
              plåtslagare i Mölle, fick jag låna hammare lämpliga för
              plåtslageriarbeten. Sedan bar det iväg ner till Ladarp där
              klippformationerna har ett fantastiskt färg - och formspråk. Efter
              att ha sett ut en lämplig klippa lade jag en av aluminiumplåtarna
              på klippan och med hammarnas hjälp började jag forma plåten efter
              underlagets form - det kändes och var helt fantastiskt. Nästa steg
              var mer dramatiskt och det tog ett år innan jag förmådde att göra
              det.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img32}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Sickan Nyström</p>
            <p>
              Steget - att ta en silverplåt för tusen kronor och lägga den på
              den grova stenen och hamra. Den låsning jag känt i början lossnade
              så småningom och under årens lopp har jag gjort ett flertal stora
              utställningar där klippornas form och struktur har legat till
              grund. Silversmide är en tidsödande teknik och under vissa
              perioder har jag haft hjälp med de hantverksmässiga processerna av
              min fru som är en duktig silversmed. Till några utställningar
              bl.a. N.K. i Stockholm och Göteborg samt Dalarnas museum i Falun
              arbetade vi tillsammans med Elisabet Wahlström von Holst. Om
              silver som material och dess formgivning finns det i dag många
              åsikter. Alltifrån den åsikten som en ung kvinnlig journalist på
              en av Stockholms större tidningar sade till mig när jag ung och
              grön ställde ut på Sveagalleriet: "Silversmide har inget
              berättigande, det hör till överklassen"! Eller den åsikten som
              eftersläntarna till funktionalismen hävdar - att den praktiska
              funktionen måste styra formen. Min arbetshypotes är den att
              materialet är ett av de mest formbara och beständiga som finns.
              Dess ljusbrytningsförmåga är unik, att arbeta i silver är för en
              ljusdyrkare nästan som att måla. Därför anser jag att silvrets
              estetiska funktion är minst lika viktig som den praktiska
              funktionen. Steget från nyttokonst till en rent estetisk
              tredimensionell form har därför aldrig varit svår för mig att ta.
              Min formgivning av silvret har sitt ursprung i teckningar,
              målningar och formexperiment av naturupplevelser. Kullabergs
              klippor, skogens träd och sommarängens grässtrån har fascinerat
              mig och blivit inspirationskällor till mitt silversmide.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img33}
                alt=""
              />
            </Zoom>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="samarbete">Samarbete</h2>
            <p>
              Jan Mårtenson, författare född i Tidaholm men nu bosatt i Malmö,
              har blivet en god vän till mig. Vi har inspirerats av varandras
              uttrycksformer. Jan har diktat kring mina tavlor och själv har jag
              försökt att i bild gestalta Jans dikter. Bilden på detta uppslag
              fick Jan av mig. Han skrev då denna dikt
              <br />
              MÖTET
              <br />
              Den gamle arbetaren i tavlans utkant: hans liv fastfruset i ett
              enda ögonblick. <br />
              Gracerna, tillfälligt lösryckta ur Botticellis Primavera, utför en
              mjuk dans i hans närhet. <br />
              Han ser dem inte: händerna, vana att bygga samhällen, nästan
              tafatta här. <br />
              De unga damerna, oupplösligt i sitt eget, märker inte hans
              närvaro. <br />
              Men det finns ett samband mellan konst och liv: Botticellis
              modeller ingår hans eget åldrande. <br />
              Här möts de, oförenliga som det flyktiga och eviga, men
              tillsammans själva bilden.
            </p>
          </div>
          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="labyrintenslitografiska">Labyrintens litografiska</h2>
            <p>
              Min son Claes Nyström började tidigt som metallarbetare på
              Tidaholmsverken, men efter fem år hade han fått nog. En dag när
              jag tryckte en litografisk upplaga, kom han till mig och frågade,
              om han inte kunde få börja arbeta som tryckare. Det fanns ett
              behov av offsettryck i kommunen, så varför inte. Han åkte på en
              del kurser samtidigt som en lämplig utrustning anskaffades.
              Därefter startade han Labyrintens litografiska tryckeri. I dag
              arbetar han åt kommunen och åt företagen i Tidaholm. För mig har
              tryckeriets utrustning varit till stor hjälp, vid tryckningen av
              större litografiska upplagor, och vid framställningar av kataloger
              o.d. Utan Claes hjälp skulle jag inte ha kunnat åstadkomma denna
              bok. För den litografiska verkstaden har tryckeriet varit ett bra
              komplement. Gästande konstnärer, har liksom jag, kunnat trycka
              sina kataloger och större upplagor här.
            </p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img44}
                alt=""
              />
            </Zoom>
          </div>

          <div className="bg-white shadow p-4 rounded mb-8">
            <h2 id="tavlor">10 reproduktioner av mina tavlor</h2>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img45}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Tema Aqaba. Oljemålning</p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img34}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Tema Aqaba. Oljemålning</p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img35}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Tema Aqaba. Oljemålning</p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img36}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Tema Aqaba. Oljemålning</p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img37}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Tema Aqaba. Oljemålning</p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img38}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Tema Knossos. Oljemålning</p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img39}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Tema Knossos. Litografi</p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img40}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Tema Knossos. Oljemålning</p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img41}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Tema Knossos. Oljemålning</p>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img42}
                alt=""
              />
            </Zoom>
            <p className="bold text-sm italic">Söndag. Litografi</p>
          </div>

          <div className="bg-white shadow p-4 rounded mb-8" id="baksida">
            <h2>Bokens baksida</h2>
            <Zoom className="flex flex-col">
              <img
                style={{
                  marginBottom: '0',
                }}
                src={img43}
                alt="Baksidan"
              />
            </Zoom>
          </div>
        </article>
      </section>
    </Layout>
  )
}

export default ContactPage
